import {graphql} from 'gatsby';
import * as React from 'react';
import {filterProducts} from '../categoryTree';
import Page, {PageProps} from '../components/Page';
import Product, {ProductData} from '../components/Product';
import Sidebar, {SidebarData} from '../components/Sidebar';

export default ({
  data: {
    products: {nodes: products},
    allCategories: {nodes: categories},
    ...sidebarData
  },
  pageContext: {categoryID},
  location,
  ...props
}: {data: HomeData; pageContext: {categoryID: string}} & PageProps) => {
  const {
    data: {name},
  } = categories.find(category => category.id === categoryID)!;
  const filteredProducts = filterProducts(products, categoryID, categories).sort((a, b) => b.data.rank - a.data.rank);
  return (
    <Page title={`The Best ${name} Products – Just Pick This`} location={location} {...props}>
      <div className="centered">
        {filteredProducts.map(({id, data}) => (
          <Product key={id} product={data} />
        ))}
        <Sidebar data={sidebarData} pathname={location.pathname} />
      </div>
    </Page>
  );
};

type HomeData = SidebarData & {
  products: {
    nodes: {
      id: string;
      data: ProductData;
    }[];
  };
  allCategories: {
    nodes: {
      id: string;
      data: {name: string; parent: {id: string}[]};
    }[];
  };
};

export const query = graphql`
  query CategoryQuery {
    ...SidebarFragment
    allCategories: allAirtable(filter: {table: {eq: "Categories"}, data: {name: {ne: null}}}) {
      nodes {
        id
        data {
          name
          parent {
            id
          }
        }
      }
    }

    products: allAirtable(
      filter: {
        table: {eq: "Products"}
        data: {
          category: {elemMatch: {id: {ne: null}}}
          brand: {ne: null}
          name: {ne: null}
          type: {ne: null}
          description: {ne: null}
          price: {ne: null}
          link: {ne: null}
          store: {elemMatch: {id: {ne: null}}}
        }
      }
    ) {
      nodes {
        id
        data {
          category {
            id
          }
          ...ProductFragment
        }
      }
    }
  }
`;
